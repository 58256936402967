<!--
  <ul class="relative flex flex-col md:flex-row gap-2">
  <li
    *ngFor="let i of [].constructor(numberOfSteps); let index = index"
    class="md:shrink md:basis-0 flex-1 group flex gap-x-2 md:block"
  >
    <div
      class="min-w-7 min-h-7 flex flex-col items-center md:w-full md:inline-flex md:flex-wrap md:flex-row text-xs align-middle"
    >
      <span
        class="size-7 flex justify-center items-center flex-shrink-0 bg-gray-100 font-medium text-gray-200 rounded-full dark:bg-gray-700 dark:text-white"
      >
        <i
          *ngIf="index === activeStepIndex"
          class="fa-solid fa-circle-dot text-primary"
        ></i>
        <i
          *ngIf="index < activeStepIndex"
          class="fa-solid fa-circle-check text-primary"
        ></i>
        <i *ngIf="index > activeStepIndex" class="fa-solid fa-circle-dot"></i>
      </span>
      <div
        [ngClass]="{ 'bg-primary': index < activeStepIndex }"
        class="mt-2 w-px h-full md:mt-0 md:ms-2 md:w-full md:h-px md:flex-1 bg-gray-200 group-last:hidden dark:bg-gray-700"
      ></div>
    </div>
    <div class="grow md:grow-0 md:mt-3 pb-5">
      <span class="block text-sm text-gray-400 text-opacity-0">
        Placeholder 1
      </span>
    </div>
  </li>
</ul> -->

<!-- Progress -->

<div class="max-w-[85rem] lg:px-8 lg:py-10 mx-auto text-[#34C0A7]">
  <div *ngIf="activeStepIndex < 7">
    <div class="flex items-center mb-2">
      <div class="font-inter text-base font-medium leading-[16.94px]">
        Step {{ activeStepIndex + 1 }}
      </div>

      <button
        *ngIf="activeStepIndex > 0"
        (click)="restart()"
        class="flex items-center justify-center w-5 h-5 ml-4 bg-[#F1FCF9] hover: text-[#34C0A7] text-[#174A44] rounded cursor-pointer"
      >
        ↻
      </button>
    </div>
    <div class="relative">
      <div
        class="flex w-full h-1 bg-[#E2E6F9] absolute mt-1 rounded-full"
        role="progressbar"
      ></div>
      <div
        class="flex flex-col justify-center absolute rounded-full h-3 overflow-hidden bg-[#34C0A7] text-xs text-white text-center whitespace-nowrap transition duration-500 dark:bg-blue-500 relative z-10"
        [style.width]="calculateWidth()"
      ></div>
    </div>
    <button
      *ngIf="activeStepIndex > 0"
      type="button"
      class="inline-flex items-center pt-2 gap-x-2 leading-[16.94px] font-normal rounded-lg border border-transparent text-[#34C0A7] hover:text-teal-600 disabled:opacity-50 disabled:pointer-events-none dark:text-gray-800 dark:hover:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
      (click)="previousStep()"
    >
      <i class="fa-solid fa-arrow-left tex-sm"></i>
      Tilbage
    </button>
  </div>
</div>
