import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DavaService {
  constructor(private http: HttpClient) {}

  // Usede to autocomplete adress
  davaAutoComplete(address: string): Observable<any> {
    return this.http
      .get<any>(
        'https://api.dataforsyningen.dk/adresser/autocomplete?q=' + address
      )
      .pipe(
        map((result) => {
          return result;
        }),
        catchError((error) => {
          console.error(error);
          return throwError(() => error);
        })
      );
  }

  // Usede to set city name based on zipCode
  davaZipCodeSearch(zipCode: number): Observable<any> {
    return this.http
      .get<any>('https://api.dataforsyningen.dk/postnumre?nr=' + zipCode)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError((error) => {
          console.error(error);
          return throwError(() => error);
        })
      );
  }
}
