<div
  class="group flex flex-col h-full bg-white border border-[#34C0A7] shadow-md rounded-xl dark:bg-neutral-900 dark:border-neutral-700 dark:shadow-neutral-700/70"
>
  <div class="relative lg:col-span-1 md:col-span-full h-full">
    <div
      *ngIf="errorIngetInsurancePrices || errorInHelvetiaPrice"
      class="absolute inset-0 bg-gray-200 opacity-50 rounded-xl blur"
    ></div>

    <div
      class="grid h-full xl:grid-cols-2 lg:grid-cols-1 md:grid-cols-2 sm:grid-cols-1"
    >
      <div class="flex flex-col">
        <div class="flex-1 bg-[#9FF0DC] bg-opacity-10">
          <div *ngIf="allHaveTogglePricetag" class="ml-2 mt-2">
            <button
              type="button"
              class="flex w-full rounded-lg cursor-pointer focus:outline-none"
              aria-haspopup="listbox"
              aria-expanded="true"
              aria-labelledby="listbox-label"
              (click)="openHelvetiaPricetag = !openHelvetiaPricetag"
            >
              <i class="fa-solid fa-ellipsis-vertical text-gray-500 w-5"></i>
            </button>

            <ul
              *ngIf="openHelvetiaPricetag"
              class="absolute mr-4 mt-1 top-z-index w-40 overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              tabindex="-1"
              role="listbox"
              aria-labelledby="listbox-label"
              aria-activedescendant="listbox-option-3"
              [@fadeInOut]
            >
              <li
                (click)="getHelvetiaPriceTag()"
                class="block w-full px-4 py-2 text-gray-500 hover:bg-gray-100"
              >
                <div class="flex justify-center items-center">
                  <span class="px-1 font-semibold">Print prisskilt</span>
                </div>
              </li>
              <li
                (click)="downloadHelvetiaPriceTag()"
                class="block w-full px-4 py-2 text-gray-500 hover:bg-gray-100"
              >
                <div class="flex justify-center items-center">
                  <span class="px-1 font-semibold">Hent prisskilt</span>
                </div>
              </li>
            </ul>
          </div>

          <div class="h-16 flex justify-center items-center mt-5">
            <img
              src="assets/images/helvetia_logo.png"
              alt="Logo 1"
              class="h-10"
            />
          </div>
        </div>
        <!--  PRICE  -->
        <div
          class="flex flex-col justify-center items-center flex-1 bg-[#9FF0DC] bg-opacity-10 py-10"
        >
          <div class="">
            <p
              *ngIf="showYearlyPrice"
              class="text-center quantico-text text-6xl font-bold"
            >
              {{ helvetiaYearlyPrice | formatNumber }}
            </p>

            <p
              *ngIf="!showYearlyPrice"
              class="text-center quantico-text text-6xl font-bold"
            >
              {{ helvetiaMonthlyPrice | formatNumber }}
            </p>

            <p class="text-center quantico-text">
              <span *ngIf="!showYearlyPrice" class="text-base">DKK/md.</span>
              <span *ngIf="showYearlyPrice" class="text-base">DKK/år</span>
            </p>
          </div>
        </div>
        <div
          class="flex flex-col justify-center flex-1 bg-[#9FF0DC] bg-opacity-10 pb-10 px-10"
        >
          <p class="text-center font-bold leading-5 text-[#168B7B]">
            Selvrisiko
          </p>

          <mat-slider
            thumbLabel
            [disableRipple]="true"
            [disabled]="errorIngetInsurancePrices || errorInHelvetiaPrice"
            [min]="0"
            [max]="tabOptions.length - 1"
            [step]="1"
            (input)="onInputChange($event)"
          >
            <!-- Thhe value is the deafult index of the array -->
            <input matSliderThumb [value]="calculateTabIndex()" />
          </mat-slider>

          <p class="text-center font-medium leading-5 text-[#168B7B]">
            {{ helvetiaExcessTab.name }}
          </p>
        </div>
      </div>
      <div class="flex flex-col">
        <!-- TOP-RIGHT PLACEHODER -->
        <div
          class="flex-1 justify-center items-center xl:block lg:hidden md:block sm:hidden"
        ></div>
        <div
          class="flex flex-col justify-center items-center flex-1 text-[#2D3748] py-8"
        >
          <div>
            <p class="py-1">
              <i class="fa-solid fa-circle-check text-[#34C0A7]"></i>
              Nøgleerstatning
            </p>
            <p class="py-1">
              <i class="fa-solid fa-circle-check text-[#34C0A7]"></i>
              Frit værkstedsvalg
            </p>
            <p class="py-1">
              <i class="fa-solid fa-circle-check text-[#34C0A7]"></i>
              Intet krav om GPS/tracker
            </p>
          </div>
        </div>
        <div class="flex flex-col justify-center items-center flex-1 pb-8">
          <button
            (click)="pickHelvetiaPrice(true)"
            [disabled]="errorIngetInsurancePrices || errorInHelvetiaPrice"
            class="py-3 px-6 shadow-lg inline-flex items-center gap-x-1 text-sm rounded-full border border-transparent bg-[#34C0A7] text-white hover:bg-teal-500 disabled:opacity-50 disabled:pointer-events-none"
          >
            <p class="text-lg font-inter">Vælg</p>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
